// project import
import users from './users';
import logs from './logs';
import reports from './reports';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [users, logs, reports]
};

export default menuItems;

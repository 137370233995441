import { useMsal } from '@azure/msal-react';
import { Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Logo from 'components/Logo';

const LoginPage = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect();
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#fafafb'
    },
    card: {
      width: '100%',
      maxWidth: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 6
    },
  };

  return (

    <Container maxWidth="sm" sx={styles.container}>
        <MainCard sx={styles.card}>
            <div style={styles.card}>
                <Logo />
                <p>Please login to the Yavrio admin dashboard.</p>
                <Button 
                    sx={{mt: 3}}
                    size="large"
                    variant="contained" 
                    onClick={handleLogin}>
                        Login
                </Button>
                {/*<Button */}
                {/*    sx={{mt: 3}}*/}
                {/*    size="large"*/}
                {/*    variant="contained" */}
                {/*    onClick={handleLogout}>*/}
                {/*        Logout*/}
                {/*</Button>*/}
            </div>
        </MainCard>
    </Container>
   

  );
};

export default LoginPage;
// material-ui
import { Typography } from '@mui/material';

// components
import RequestLogsList from "../components/logs/RequestLogsList";

// ==============================|| SAMPLE PAGE ||============================== //

const RequestLogsPage = () => (
    <>
        <h2>Request Logs</h2>
        <RequestLogsList logType='request' />
    </>
);

export default RequestLogsPage;

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';

// MSAL
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3001';

const msalConfiguration = {
  auth: {
      clientId: "359df474-b7cc-4983-bdc0-50485229d2d0",
      authority: 'https://login.microsoftonline.com/yavrio.onmicrosoft.com',
      redirectUri: `${baseUrl}/users`,
  },
  cache: {
      cacheLocation: "localStorage", // "sessionStorage" or"localStorage"
      storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(msalConfiguration);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <MsalProvider instance={pca}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import apiCall from './api.service';

export async function getRequestLogs(page = 1, pageSize = 20) {
    const url = `admin/logs/request?pageSize=${pageSize}&page=${page}`;
    const requestLogsResponse = await apiCall('get', url);

    if (requestLogsResponse.status !== 200) {
        throw new Error('Failed to get request logs');
    } else {
        return requestLogsResponse.data.data;
    }
}

export async function getRequestLog(requestId) {
    const url = `admin/logs/request/${requestId}`;
    const requestLogsResponse = await apiCall('get', url);

    if (requestLogsResponse.status !== 200) {
        throw new Error('Failed to get request logs');
    } else {
        return requestLogsResponse.data.data;
    }
}

export async function getExternalRequestLogs(page = 1, pageSize = 20) {
    const url = `admin/logs/external?pageSize=${pageSize}&page=${page}`;
    const requestLogsResponse = await apiCall('get', url);

    if (requestLogsResponse.status !== 200) {
        throw new Error('Failed to get external request logs');
    } else {
        return requestLogsResponse.data.data;
    }
}

export async function getExternalRequestLog(requestId) {
    const url = `admin/logs/external/${requestId}`;
    const requestLogsResponse = await apiCall('get', url);

    if (requestLogsResponse.status !== 200) {
        throw new Error('Failed to get request logs');
    } else {
        return requestLogsResponse.data.data;
    }
}
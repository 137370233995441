import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingScreen() {
    return (
        <div className='loading-screen h-full'>
            <div className='flex flex-col items-center justify-center h-full'>
                <CircularProgress color='primary'/>
                <h2 className='mt-5'>Loading...</h2>
            </div>
        </div>
    );
}
// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';

import { AuthenticatedTemplate, UnauthenticatedTemplate  } from '@azure/msal-react';
import LoginPage from 'pages/LoginPage';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (

  <ThemeCustomization>
    <ScrollTop>
      <AuthenticatedTemplate>
        <Routes />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </ScrollTop>
  </ThemeCustomization>
);

export default App;

// assets
import { UserOutlined, CheckCircleOutlined  } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  CheckCircleOutlined 
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'users',
  title: 'Users',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Users (v1)',
      type: 'item',
      url: '/users',
      icon: icons.UserOutlined,
      target: false
    },
    {
      id: 'licences',
      title: 'Licences',
      type: 'item',
      url: '/licences',
      icon: icons.CheckCircleOutlined ,
      target: false
    },
  ]
};

export default pages;

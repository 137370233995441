import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useMsal } from '@azure/msal-react';

const LogoutBlock = () => {

    const { instance, accounts } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
      };

    return (
        <Stack 
            direction="row" 
            spacing={4} 
            divider={<Divider orientation="vertical" flexItem  />}
            alignItems="center"
            sx={{ ml: 'auto' }}
        >
            <h4>{accounts[0]?.username}</h4>
            <Button 
                    size="medium"
                    variant="contained" 
                    onClick={handleLogout}>
                        Logout
            </Button>
        </Stack>
    );
};

export default LogoutBlock;

import axios from 'axios';

const API_URL = process.env.REACT_APP_SQUID_URL;
const AUTH_TOKEN = process.env.REACT_APP_SQUID_KEY;

const apiCall = async function(method, url, data) {
    const config = {
        method: method,
        url: `${API_URL}/${url}`,
        headers: {
            'Authorization': `Bearer ${AUTH_TOKEN}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        return await axios(config);
    } catch (error) {
        throw error;
    }
}

export default apiCall;
import {Link, useParams} from 'react-router-dom';

// material-ui
import Grid from '@mui/material/Grid';
import MainCard from "../components/MainCard";
import {Box} from "@mui/material";
import {getExternalRequestLog, getRequestLog} from "../services/logs.service";
import {useEffect, useState} from "react";

// ==============================|| SAMPLE PAGE ||============================== //

const RequestLogPage = () => {

    const { id } = useParams();

    const [requestLog, setRequestLog] = useState(null);

    const getRequestLogData = async () => {
        try {
            const requestLog = await getExternalRequestLog(id);
            setRequestLog(requestLog);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getRequestLogData();
    }, []);

    return (
        <>
            <div><Link to='/external-logs'>Back</Link><h2>Request Id: {id}</h2></div>
            <div className='flex flex-col'>
                <MainCard title="Request Details" sx={{ marginBottom: '1rem' }}>
                    <Box sx={{width: '100%'}}>
                        <p>Endpoint: <b>{requestLog && requestLog.requestUrl}</b></p>
                        <p>Method: <b>{requestLog && requestLog.requestMethod}</b></p>
                        <p>Status: <b>{requestLog && requestLog.responseStatus}</b></p>
                        <p>Created At: <b>{requestLog && requestLog.createdAt}</b></p>
                    </Box>
                </MainCard>
                <MainCard title="Request Body" sx={{ marginBottom: '1rem' }}>
                    <Box sx={{ width: '100%' }}>
                        <pre>{requestLog && requestLog.requestBody ? JSON.stringify(requestLog.requestBody, null, 4) : null }</pre>
                    </Box>
                </MainCard>
                <MainCard title="Response Body" sx={{ marginBottom: '1rem' }}>
                    <Box sx={{ width: '100%' }}>
                        <pre>{requestLog && requestLog.responseBody ? JSON.stringify(requestLog.responseBody, null, 4) : null }</pre>
                    </Box>
                </MainCard>
            </div>
        </>
    );
}

export default RequestLogPage;

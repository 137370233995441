import React from "react";

function YavrioLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="100"
      version="1.1"
      viewBox="0 0 702.08 143.04"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 143.04)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path
            fill="#404a6a"
            d="M1706.19.328V429.04l-377.83 606.011h215.08l263.02-444.683 258.67 444.683h209.27l-374.94-604.573V.328h-193.27"
          ></path>
          <path
            fill="#404a6a"
            d="M2637.49 136.93v117.715c0 17.445-1.71 32.179-5.11 44.335-3.4 12.083-10.42 21.993-21.07 29.786-10.65 7.746-25.67 13.293-45.03 16.699-19.4 3.379-45.07 5.09-77.01 5.09h-21.81c-55.21 0-92.53-6.797-111.91-20.325-19.38-13.601-29.06-38.289-29.06-74.109v-27.637c0-31.007 8.94-53.996 26.89-68.996 17.9-15.047 51.07-22.558 99.54-22.558zm183.1 0c0-51.36-11.63-86.989-34.87-106.79C2762.45 10.263 2722.24.329 2665.1.329h-236.87c-103.69 0-177.07 19.344-220.19 58.152-43.12 38.711-64.64 94.458-64.64 167.102v31.973c0 80.402 23.72 138.8 71.17 175.129 47.51 36.328 121.6 54.461 222.37 54.461 50.36 0 92.26-4.11 125.69-12.332 33.44-8.254 58.36-17.708 74.86-28.333v26.133c0 26.192-2.68 47.989-7.99 65.434-5.37 17.437-14.34 31.258-26.89 41.433-12.62 10.157-29.8 17.176-51.59 21.067-21.82 3.855-49.22 5.797-82.13 5.797h-61.03c-22.29 0-44.83-.242-67.55-.703-22.78-.516-45.31-.985-67.6-1.473-22.28-.496-42.63-.734-61.01-.734l30.48 152.597c27.17.938 61.04 1.684 101.75 2.18 40.69.469 85.24.715 133.68.715 118.18 0 204.92-20.832 260.12-62.461 55.24-41.672 82.86-107.063 82.86-196.211V136.93"
          ></path>
          <path
            fill="#404a6a"
            d="M3076.32 756.031l183.1-562.39 188.93 562.39h191.83L3362.61.328h-210.72l-278.96 755.703h203.39"
          ></path>
          <path
            fill="#404a6a"
            d="M3881.33.328H3692.4v497.004c0 48.469 6.78 89.402 20.39 122.813 13.53 33.445 33.4 60.312 59.53 80.664 26.18 20.351 58.15 35.117 95.93 44.336 37.79 9.191 80.91 13.781 129.32 13.781h94.46c26.17 0 51.83-.481 77.02-1.449l-30.5-156.93c-19.39.957-40.67 1.668-63.96 2.176-23.21.476-42.63.711-58.11.711-31.03 0-55.23-2.887-72.67-8.7-17.44-5.82-31.02-14.296-40.7-25.429-9.66-11.164-15.74-25.215-18.15-42.168-2.43-16.961-3.63-37.063-3.63-60.301V.328"
          ></path>
          <path
            fill="#404a6a"
            d="M4266.46 756.031h188.89V.328h-188.89zm207.81 213.625c0-36.847-8.25-63.222-24.73-79.191-16.44-15.985-45.55-23.992-87.18-23.992-41.64 0-70.74 8.007-87.21 23.992-16.45 15.969-24.7 42.344-24.7 79.191 0 35.824 8.25 61.984 24.7 78.454 16.47 16.48 45.57 24.71 87.21 24.71 41.63 0 70.74-8.23 87.18-24.71 16.48-16.47 24.73-42.63 24.73-78.454"
          ></path>
          <path
            fill="#404a6a"
            d="M5084 419.676c0 42.187-2.31 76.394-6.88 102.527-4.58 26.152-12.63 46.113-24.09 59.887-11.46 13.758-27.5 22.918-48.15 27.527-20.66 4.578-47.45 6.875-80.5 6.875-33.03 0-60.11-2.297-81.2-6.875-21.11-4.609-37.39-13.769-48.84-27.527-11.5-13.774-19.48-33.735-24.07-59.887-4.64-26.133-6.88-60.34-6.88-102.527v-81.192c0-42.199 2.24-76.144 6.88-101.836 4.59-25.714 12.57-45.421 24.07-59.16 11.45-13.761 27.73-22.949 48.84-27.519 21.09-4.582 48.17-6.887 81.2-6.887 33.05 0 59.84 2.305 80.5 6.887 20.65 4.57 36.69 13.758 48.15 27.519 11.46 13.739 19.51 33.446 24.09 59.16 4.57 25.692 6.88 59.637 6.88 101.836zm-159.62 339.941c61.46 0 113.72-5.539 156.85-16.562 43.11-10.977 78.45-29.551 105.99-55.696 27.5-26.148 47.45-61.238 59.84-105.269 12.37-44.043 18.58-99.113 18.58-165.149v-74.308c0-66.063-6.21-121.102-18.58-165.145-12.39-44.023-32.34-79.105-59.84-105.277-27.54-26.133-62.88-44.711-105.99-55.7C5038.1 5.489 4985.84 0 4924.38 0c-62.39 0-115.14 5.488-158.25 16.512C4723 27.5 4687.71 46.078 4660.19 72.21c-27.54 26.172-47.49 61.254-59.86 105.277-12.36 44.043-18.57 99.082-18.57 165.145v74.308c0 66.036 6.21 121.106 18.57 165.149 12.37 44.031 32.32 79.121 59.86 105.269 27.52 26.145 62.81 44.719 105.94 55.696 43.11 11.023 95.86 16.562 158.25 16.562"
          ></path>
          <path
            fill="#219d5d"
            d="M224.105 1035.19L0 694.871 441.859.648l221.477 346.086-439.231 688.456"
          ></path>
          <path
            fill="#24b269"
            d="M664.805 690.73l220.199 343.95H444.723z"
          ></path>
          <path
            fill="#24b269"
            d="M884.863 692.918L663.336 346.734 441.879.648l.168-.277H884.75l.113.277 443.227 690.063-219.99 344.479-223.237-342.272"
          ></path>
          <path
            fill="#c6c5c5"
            d="M441.805.648l.015.063.012-.02-.027-.043"
          ></path>
          <path
            fill="#197947"
            d="M441.832.691l-.012.02 115.657 511.887 105.785-165.864L441.832.691"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default YavrioLogo;

// assets
import { DollarOutlined, UnorderedListOutlined } from '@ant-design/icons';

// icons
const icons = {
  DollarOutlined,
  UnorderedListOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'logs',
  title: 'Logs',
  type: 'group',
  children: [
    {
      id: 'requestLogs',
      title: 'Request Logs',
      type: 'item',
      url: '/request-logs',
      icon: icons.UnorderedListOutlined,
      target: false
    },
    {
      id: 'externalRequestLogs',
      title: 'External Request Logs',
      type: 'item',
      url: '/external-logs',
      icon: icons.UnorderedListOutlined,
      target: false
    }
  ]
};

export default pages;

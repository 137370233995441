import * as React from 'react';
import {Suspense, useEffect, useState} from 'react';
// import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { darken, lighten, styled } from '@mui/material/styles';
import {getExternalRequestLogs, getRequestLogs} from "../../services/logs.service";

// project import
import MainCard from 'components/MainCard';
import LoadingScreen from "../utils/LoadingScreen";
import {useNavigate} from "react-router-dom";


const columns = [
  {
      field: 'requestId',
      headerName: 'Request ID',
      flex: 1,
      minWidth: 150
  },
  {
    field: 'requestMethod',
    headerName: 'Method',
      flex: 0.3,
    minWidth: 70,
    editable: false,
  },
    {
        field: 'responseStatus',
        headerName: 'Status',
        flex: 0.3,
        minWidth: 70,
        editable: false,
    },
    {
        field: 'requestUrl',
        headerName: 'URL',
        flex: 1.5,
        minWidth: 200,
        editable: false,
    },
    {
        field: 'requestBody',
        headerName: 'Request Body',
        flex: 2,
        minWidth: 150,
        editable: false,
    },
    {
        field: 'responseBody',
        headerName: 'Response Body',
        flex: 2,
        minWidth: 150,
        editable: false,
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1,
        minWidth: 150,
        editable: false,
    }
];

export default function RequestLogsList({logType}) {

  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  const updateRequestLogs = async () => {

    console.log('Updating request logs...');

    try {
        let requestLogsData;

        if (logType === 'request') {
            requestLogsData = await getRequestLogs(1, 20);
        } else {
            requestLogsData = await getExternalRequestLogs(2, 20);
        }

      // Get data values from the response and apply to rows, only for the columns that are defined
        const selectedRows = requestLogsData.map((log) => {
            return {
                id: log.id,
                requestId: log.requestId,
                requestMethod: log.requestMethod,
                responseStatus: log.responseStatus,
                requestUrl: log.requestUrl,
                requestBody: JSON.stringify(log.requestBody),
                responseBody: JSON.stringify(log.responseBody),
                createdAt: log.createdAt,
            };
        });

        setRows(selectedRows);

    } catch (error) {
      console.error(error);
    }
  };

    useEffect(() => {
        updateRequestLogs();
    }, []);

  return (
      <MainCard title="Request Logs" sx={{ maxWidth: '100%' }}>
        <Box sx={{ width: '100%' }}>
            <Suspense fallback={<LoadingScreen/>}>
                <div style={{width: '100%'}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}
                        pageSizeOptions={[20]}
                        disableRowSelectionOnClick
                        onRowClick={(params) => {
                            navigate(`/${logType}-logs/${params.row.requestId}`);
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                </div>
            </Suspense>
        </Box>
      </MainCard>
  );
}

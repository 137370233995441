import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import RequestLogsPage from "../pages/RequestLogsPage";
import RequestLogPage from "../pages/RequestLogPage";
import ExternalRequestLogsPage from "../pages/ExternalRequestLogsPage";
import ExternalRequestLogPage from "../pages/ExternalRequestLogPage";
import {Navigate} from "react-router";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// render - my pages
const UsersPage = Loadable(lazy(() => import('pages/UsersPage')));
const UserPage = Loadable(lazy(() => import('pages/UserPage')));
const LicencesPage = Loadable(lazy(() => import('pages/LicencesPage')));
const LicencePage = Loadable(lazy(() => import('pages/LicencePage')));
const UserLogPageV1 = Loadable(lazy(() => import('pages/UserLogPage-V1')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/users" />,
    },
    {
      path: 'users',
      element: <UsersPage />,
    },
    {
      path: 'users/:id',
      element: <UserPage />,
    },
    {
      path: 'users/:id/logs/:userLogId',
      element: <UserLogPageV1 />
    },
    {
      path: 'licences',
      element: <LicencesPage />
    },
    {
      path: 'licences/:id',
      element: <LicencePage />
    },
    {
      path: 'request-logs',
      element: <RequestLogsPage />
    },
    {
      path: 'request-logs/:id',
      element: <RequestLogPage />
    },
    {
      path: 'external-logs',
      element: <ExternalRequestLogsPage />
    },
    {
      path: 'external-logs/:id',
      element: <ExternalRequestLogPage />
    },
    {
      path: 'reports',
      element: <UsersPage />
    },
  ]
};

export default MainRoutes;
